import interceptor from "../interceptor/interceptor";
import baseClient from "./base_client";

export default {
  post: async (endpoint, data) => {
    // console.log(data);
    // console.log(baseClient.signature(data));
    return baseClient.axiosForm
      .post(endpoint, data, {
        headers: { "X-SFC-Signature": baseClient.signature(data) },
      })
      .then((response) => {
        // console.log(data);
        // console.log(response);
        // console.log(baseClient.signature(data));
        return interceptor.validate(response);
      })
      .catch((error) => {
        return interceptor.validate(error.response);
      });
  },
  _post: async (endpoint, data) => {
    // console.log('_post:', data);
    // console.log('_post baseClient:', baseClient.signature(data));
    return baseClient.axiosForm
      .post(endpoint, data, {
        headers: { "X-SFC-Signature": baseClient.signature(data) },
      })
      .then((response) => {
        // console.log(data);
        // console.log(response);
        // console.log(baseClient.signature(data));
        return interceptor.validate(response);
      })
      .catch((error) => {
        return interceptor.validate(error.response);
      });
  },
  patch: async (endpoint, data) => {
    return baseClient.axiosForm
      .patch(endpoint, data, {
        headers: { "X-SFC-Signature": baseClient.signature(data) },
      })
      .then((response) => {
        return interceptor.validate(response);
      })
      .catch((error) => {
        return interceptor.validate(error.response);
      });
  },
  get: async (endpoint, data) => {
    return baseClient.axiosForm
      .get(endpoint, {
        headers: { "X-SFC-Signature": baseClient.signature(endpoint) },
      })
      .then((response) => {
        return interceptor.validate(response);
      })
      .catch((error) => {
        return interceptor.validate(error.response);
      });
  },
};
