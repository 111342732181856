<template>
  <div class="mt-2 text-left flex gap-2 items-center box-border w-full ">
    <div
      class="
        mt-2
        h-full
        sm:w-10/12
        w-11/12
        border border-gray-500
        rounded-lg
        text-xs
        md:text-sm
        flex flex-row
        gap-2
        items-center
      "
    >
      <img
        src="@/assets/icons/file-document.svg"
        class="h-5 my-auto mr-2 pl-5"
      />
      <p class="text-xs text-gray-500 truncate">{{ value }}</p>
    </div>
    <button
      class="
        h-full
        sm:w-44
        w-24
        rounded-md
        h-10
        sm:mx-auto
        mx-0
        sm:px-5
        mt-auto
        hover:bg-blue-500
        general-color-button
        flex flex-row
        justify-center
        items-center
      "
      @click="onDownload(url_download, value)"
    >
      <img src="@/assets/icons/download.svg" class="h-5 my-auto sm:mr-2" />
      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 16px" spin />
        </a-spin>
      </div>
      <span class="text-xs text-white hidden sm:block">{{ legend }}</span>    
    </button>
  </div>
</template>

<script>
export default {
  props: ["id", "label", "value", "url_download"],
  data() {
    return {
      loading: false,
      invalid: false,
      feedback: "",
      legend: "Descargar",
      separator: '_id_storage__SFC'
    };
  },
  methods: {
    async onDownload(url_download, value) {
      
      // get url if this does not exists
      if(url_download.includes(this.separator)){

        const id_storage = url_download.split(this.separator)[0];

        url_download = await this.getUrl(id_storage);
      }

      let a = document.createElement("a");
      a.href = url_download;
      a.target = "_blank";
      a.download = value;
      a.click();
    },
    async getUrl(id_storage){
      this.loading = true;

      let query = `?id_storage=${id_storage}`;
      
      let { error, data } = await this.$api.getAttachmentUrl(query);
      
      this.loading = false;

      if (error) this.legend = "Ocurrió un error con la consulta";
      
      if(data){
        this.legend = "Descargar";
        return data.url_file;
      }
      
      return undefined;
    }
  },
  computed: {},
};
</script>

<style>
</style>