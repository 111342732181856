export default [

    {
        path: 'users',
        component: () => import('@/views/dashboard/users/Managment.vue'),
        meta: {
            title: 'Gestión de Usuarios',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de usuarios',link:'/dashboard/users'},
            ]
        },
    },
    {
        path: 'users/add',
        component: () => import('@/views/dashboard/users/Add'),
        meta: {
            title: 'Crear Usuario',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de usuarios',link:'/dashboard/users'},
                {label: 'Crear Usuario'}
            ]
        }
    },
    {
        path: 'users/edit',
        component: () => import('@/views/dashboard/users/Edit'),
        meta: {
            title: 'Editar Usuario',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de usuarios',link:'/dashboard/users'},
                {label: 'Editar Usuario'}
            ]
        }
    },
]