<template>
    <div class="h-full w-full mt-5 text-left flex flex-col">
        <label :for="id" 
            class="mb-2 text-xs md:text-xs text-gray-500 text-opacity-80"
            :class="{'text-red-500': invalid}"><b>{{label}}</b><span v-if="legend" class="pl-1 text-xs">(mínimo 8 caracteres, una mayúscula, un número y un  carácter especial)</span> <span v-if="required" class="text-red-500">*</span></label>
        <a-input-password 
            :placeholder="placeholder" 
            @input="$emit('input', $event.target.value)"
            @keyup="checkInput"
            :disabled="disabled"
            :value="value"
            :autocomplete="autocomplete" />
        <p v-if="invalid" class="mt-2 text-xs text-red-500">{{feedback ? feedback : 'Este campo es obligatorio'}}</p>
    </div>
</template>

<script>
export default {
    props: ['id', 'label','placeholder','value', 'disabled', 'danger', 'required', 'autocomplete', 'legend'],
    data(){
        return {
            invalid: false,
            feedback: '',
            showPassword: false,
        }
    },
    methods: {
        toogleShowPassword(){
            this.showPassword = !this.showPassword
        },
        checkInput(){
            if(this.value == "" && this.required){
                this.invalid = true;
                this.feedback = 'Este campo es obligatorio'
            } else {
                this.invalid = false;
                this.feedback = '';
            }
        },
        setInvalid(invalid){
            if(this.required){
                this.invalid = invalid;
            }
        }
    },
    computed: {
        classObject: function(){
            let classes = ['border-gray-300', 'placeholder-gray-400']
            if(this.danger || this.invalid){
                classes = ['border-red-500', 'placeholder-red-500']
            }
            return classes;
        }
    }
}
</script>

<style>

</style>