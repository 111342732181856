const url = "/dcf/recommendations/";

import generateFormData from "../../../utils/generateFormData";
import getFormDataPolymorphic from "../../../utils/getFormDataPolymorphic";

export default {
  createRecommendation: (axios, signature, data) => {
    return axios
      .post(`${url}create/`, data, {
        headers: { "X-SFC-Signature": signature(data) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  recommendationStep1: (axios, signature, recommendation_id, step, form) => {
    return axios
      .post(`${url}${recommendation_id}/step/${step}/`, form, {
        headers: { "X-SFC-Signature": signature(form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  recommendationSteps: (
    axios,
    signature,
    recommendation_id,
    step,
    form,
    step4Id
  ) => {
    let response = null;
    if (step === 4) {
      const formData = getFormDataPolymorphic(form.form, form?.files);
      let entries = Object.entries(form.form);
      let newform = {};
      entries.forEach((entry) => {
        if (form.form[1] !== null && form.form[1] !== undefined)
          newform[entry[0]] = entry[1] + "";
      });
      response = axios.put(
        `${url}${recommendation_id}/step/${step}/${step4Id}/`,
        formData,
        {
          headers: { "X-SFC-Signature": signature(newform) },
        }
      );
    } else if (step === 6) {
      const formData = getFormDataPolymorphic(form.form, form?.files);
      let entries = Object.entries(form.form);
      let newform = {};
      entries.forEach((entry) => {
        if (form.form[1] !== null && form.form[1] !== undefined)
          newform[entry[0]] = entry[1] + "";
      });
      response = axios.put(
        `${url}${recommendation_id}/step/${step}/`,
        formData,
        {
          headers: { "X-SFC-Signature": signature(newform) },
        }
      );
    } else {
      response = axios.put(`${url}${recommendation_id}/step/${step}/`, form, {
        headers: {
          "X-SFC-Signature": signature(form),
          "Content-Type": "application/json",
        },
      });
    }
    return response
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  addActionPlan: (axios, signature, recommendation_id, actionPlan) => {
    const formData = getFormDataPolymorphic(
      actionPlan.form,
      actionPlan.files,
      "files",
      "file_type_id",
      1
    );
    let data = actionPlan.form;
    actionPlan.files.forEach(
      (_, index) => (data[`files[${index}]file_type_id`] = "1")
    );
    return axios
      .post(`${url}${recommendation_id}/action-plans/`, formData, {
        headers: { "X-SFC-Signature": signature(data) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  addFilesToActionPlan: (
    axios,
    signature,
    recommendation_id,
    actionPlanId,
    typeFile,
    actionPlan
  ) => {
    const formData = getFormDataPolymorphic(
      actionPlan.form,
      actionPlan.files,
      "files",
      "file_type_id",
      typeFile
    );
    actionPlan.files.forEach(
      (_, index) => (actionPlan.form[`files[${index}]file_type_id`] = typeFile)
    );
    let entries = Object.entries(actionPlan.form);
    let form = {};
    entries.forEach((entry) => {
      if (actionPlan.form[1] !== null && actionPlan.form[1] !== undefined)
        form[entry[0]] = entry[1] + "";
    });
    const sign = signature(form);
    return axios
      .put(
        `${url}${recommendation_id}/action-plans/${actionPlanId}/`,
        formData,
        {
          headers: { "X-SFC-Signature": sign },
        }
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getActionPlans: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/action-plans/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  progressReport: (axios, signature, recommendation_id, progressReport) => {
    const formData = getFormDataPolymorphic(
      progressReport.form,
      progressReport.files,
      "files",
      "progress_file_type",
      1
    );
    let data = progressReport.form;
    progressReport.files.forEach(
      (_, index) => (data[`files[${index}]progress_file_type`] = "1")
    );
    return axios
      .post(`${url}${recommendation_id}/progress-reports/`, formData, {
        headers: { "X-SFC-Signature": signature(data) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  addFilesToProgressReport: (
    axios,
    signature,
    recommendation_id,
    progressReportId,
    typeFile,
    progressReport
  ) => {
    const formData = getFormDataPolymorphic(
      progressReport.form,
      progressReport.files,
      "files",
      "progress_file_type",
      typeFile
    );
    progressReport.files.forEach(
      (_, index) =>
        (progressReport.form[`files[${index}]progress_file_type`] = typeFile)
    );
    let entries = Object.entries(progressReport.form);
    let form = {};
    entries.forEach((entry) => {
      if (
        progressReport.form[1] !== null &&
        progressReport.form[1] !== undefined
      )
        form[entry[0]] = entry[1] + "";
    });
    const sign = signature(form);
    return axios
      .put(
        `${url}${recommendation_id}/progress-reports/${progressReportId}/`,
        formData,
        {
          headers: { "X-SFC-Signature": sign },
        }
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  tracingsRecommendations: (
    axios,
    signature,
    recommendation_id,
    tracingRecommmendation
  ) => {
    const formData = getFormDataPolymorphic(
      tracingRecommmendation.form,
      tracingRecommmendation.files
    );
    return axios
      .post(`${url}${recommendation_id}/tracing-recommendations/`, formData, {
        headers: { "X-SFC-Signature": signature(tracingRecommmendation.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  entityAnswer: (axios, signature, recommendation_id, answer) => {
    const formData = getFormDataPolymorphic(answer.form, answer.files);
    let entries = Object.entries(answer.form);
    let form = {};
    entries.forEach((entry) => {
      if (answer.form[1] !== null && answer.form[1] !== undefined)
        form[entry[0]] = entry[1] + "";
    });
    const sign = signature(form); //"test-non-signature";
    return axios
      .put(`${url}${recommendation_id}/answer/`, formData, {
        headers: { "X-SFC-Signature": sign },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  closeRecommendation: (axios, signature, recommendation_id, close) => {
    const formData = getFormDataPolymorphic(close.form, close.files);
    let entries = Object.entries(close.form);
    let form = {};
    entries.forEach((entry) => {
      if (close.form[1] !== null && close.form[1] !== undefined)
        form[entry[0]] = entry[1] + "";
    });
    const sign = signature(form);
    return axios
      .put(`${url}${recommendation_id}/close/`, formData, {
        headers: { "X-SFC-Signature": sign },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  finishRecommendation: (axios, signature, recommendation_id) => {
    return axios
      .post(
        `${url}${recommendation_id}/finish/`,
        {},
        { headers: { "X-SFC-Signature": signature({}) } }
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  //#region  GETTERS
  getResource: (axios, endpoint) => {
    return axios
      .get(`${endpoint}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getRecommendations: (axios, query) => {
    return axios
      .get(`${url}list/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getRecommendationsStatus: (axios, query) => {
    return axios
      .get(`${url}status/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  getStep0: (axios, annual_report_id) => {
    return axios
      .get(`${url}${annual_report_id}/company`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getSteps: (axios, recommendation, step) => {
    return axios
      .get(`${url}${recommendation}/step/${step}/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  getActionPlans: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/action-plans/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  getProgressReports: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/progress-reports/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  getTracings: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/tracing-recommendation/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getEntityAnswer: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/answer/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getCloseRecommendation: (axios, recommendation_id) => {
    return axios
      .get(`${url}${recommendation_id}/close/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  //#endregion
  getRecommendationByCompany: (axios) => {
    return axios
      .get(`${url}list/`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
};
