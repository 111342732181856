export default [
  {
    path: "alerts/notifications",
    component: () => import("@/views/dashboard/alerts/Alerts.vue"),
    meta: {
      title: "Alertas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Alertas / Notificaciones" },
      ],
    },
  },
  {
    path: "alerts/settings",
    component: () => import("@/views/dashboard/alerts/Settings.vue"),
    meta: {
      title: "Configuración de alertas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Alertas / Configurar alertas" },
      ],
    },
  },
];
