const getDefaultState = () => {
    return {
        complaints: [],
        totalPages: 0,
        currentPage: 0,
        nextPage: '',
        prevPage: ''
    }
}
const state = getDefaultState;
const mutations = {
    setComplaints(state, payload){
        state.complaints = payload.results
        state.nextPage = payload.next
        state.prevPage = payload.previous
        state.totalPages = payload.pages,
        state.currentPage = payload.current_page
    },
}

const actions = {
    async loadComplaints({commit, rootState, dispatch}){
        const {data, error} = await this._vm.$api.getComplaints();
        if(error) (console.error(error))
        if(data){
            commit('setComplaints', data)
        }
    },
    async nextPage({commit, state}){
        if(!state.nextPage) return;
        let query = new URLSearchParams(state.nextPage.split('/?')[1]);
        const {data, error} = await this._vm.$api.getComplaintsByPage(query);
        if(error) (console.error(error))
        if(data){
            commit('setComplaints', data)
        }
    },
    async prevPage({commit, state}){
        if(!state.prevPage) return;
        let query = new URLSearchParams(state.prevPage.split('/?')[1]);
        const {data, error} = await this._vm.$api.getComplaintsByPage(query);
        if(error) (console.error(error))
        if(data){
            commit('setComplaints', data)
        }
    }
}

const getters = {
    getComplaintById: (state) => (id) => {
        return state.complaints.find(complaint => complaint.codigo_queja === id);
    }
}

const complaints = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default complaints;