export default (files, limit=30) => {
    let reachedLimit = true ;
    let size = 0;
    files.forEach(item =>{
        size += item.size;
    }) 
    size = size / (1024*1024);

    if(size <= limit){
        reachedLimit = false;
    }
    return reachedLimit;
}