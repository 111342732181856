const url = '/usersmanagement/'
const url_auxiliary = '/inactivate_users/'
export default {

    /**
     * Service for creater admin user
     * @param {*} sign signature
     * @param {*} axios instance axios
     * @param {*} data user data
     * @returns 
     */
    createUser: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for list users
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    readUser: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for retrieve user data
     * @param {*} axios 
     * @param {*} id user id
     * @returns 
     */
    getUser: (axios, id) => {
        return axios.get(`${url}${id}/`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    updateUser: (axios, sign, data, id) => {
        return axios.patch(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    inactivateMassiveUsers: (axios, sign, data) => {
        return axios.post(`${url_auxiliary}`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    },  
    changeStateUser: (axios, sign, data, id) => {
        return axios.put(`${url_auxiliary}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    }
}
