const url = '/queja/';
const url_solicitude = '/jurisdiccional-servi/create-solicitude';
export default {
  getComplaints(axios) {
    return axios
      .get(url + "?page_size=10")
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  getComplaintById(axios, id) {
    return axios
      .get(`${url + id}/`)
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  getComplaintsByPage(axios, query) {
    return axios
      .get(url + "?" + query)
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  setComplaint(signature, axios, form, files) {
    let formComplaint = form;
    let formData = new FormData();
    let entries = Object.entries(formComplaint);
    Object.keys(formComplaint).map((key, index) => {
      form[key] = form[key] + "";
    });
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.complaintFiles.forEach((file, index) => {
      formData.append(`file-complaint-${index}`, file);
    });
    files.authorizationFiles.forEach((file, index) => {
      formData.append(`file-apoderado-${index}`, file);
    });
    let sign = signature(formComplaint);
    return axios
      .post(url, formData, {
        headers: { "X-SFC-Signature": sign },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  setSolicitude(signature, axios, form, files) {
    let formComplaint = form;
    let formData = new FormData();
    let entries = Object.entries(formComplaint);
    Object.keys(formComplaint).map((key, index) => {
      form[key] = form[key] + "";
    });
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.complaintFiles.forEach((file, index) => {
      formData.append(`file-complaint-${index}`, file);
    });
    files.authorizationFiles.forEach((file, index) => {
      formData.append(`file-apoderado-${index}`, file);
    });
    let sign = signature(formComplaint);
    return axios
      .post(url_solicitude, formData, {
        headers: { "X-SFC-Signature": sign , "Content-Type": "multipart/form-data"},
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  desistComplaint(signature, axios, id, form) {
    return axios
      .patch(`${url + id}/`, form, {
        headers: { "X-SFC-Signature": signature(form) },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  desistComplaintAttach(signature, axios, id, form, files) {
    let formComplaint = form;
    let formData = new FormData();
    let entries = Object.entries(formComplaint);
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file);
    });

    return axios
      .patch(`${url + id}/`, formData, {
        headers: { "X-SFC-Signature": signature(formData) },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  setReplica(signature, axios, form, files) {
    let formReplica = form;
    let formData = new FormData();
    Object.keys(formReplica).map((key, index) => {
      formReplica[key] = formReplica[key] + "";
    });
    Object.entries(formReplica).forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file);
    });
    return axios
      .post("quejareplica/", formData, {
        headers: { "X-SFC-Signature": signature(form) },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  getComplaintsNoRated(axios) {
    return axios
      .get("/quejacalificacion/")
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  setCalification(signature, axios, form) {
    return axios
      .post("quejacalificacion/", form, {
        headers: { "X-SFC-Signature": signature(form) },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  setAnexos(signature, axios, form, files) {
    let formReplica = form;
    let formData = new FormData();
    Object.keys(formReplica).map((key, index) => {
      formReplica[key] = formReplica[key] + "";
    });
    Object.entries(formReplica).forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.forEach((file, index) => {
      formData.append(`file-anexos-${index}`, file);
    });
    return axios
      .post("nuevos_anexos/", formData, {
        headers: { "X-SFC-Signature": signature(form) },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  checkComplaint(axios, id) {
    return axios
      .get(`${url + id}/`)
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
  getComplaintQuery: (axios, query) => {
    return axios
      .get(`${url}${query}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => ({ error: error?.response?.data }));
  },
};