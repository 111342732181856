<template>
  <div class="w-full">
    <AccordionItem v-for="(item, index) in items" :key="index" :item="item" />
  </div>
</template>

<script>
import AccordionItem from './AccordionItem.vue'
export default {
  props: ['items'],
  components: {
    AccordionItem
  },
  data(){
    return {

    }
  }
}
</script>

<style>

</style>