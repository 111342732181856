<template>
  <div
    class="flex flex-col w-full h-auto mt-5 items-center justify-center text-center"
  >
    <label
      class="w-full text-left text-xs md:text-sm text-gray-500 text-opacity-80"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <div
      class="w-full px-12 py-5 mt-2 rounded-lg border-dashed border-2 border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
      :disabled="disabled"
        type="file"
        :id="id"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        :accept="accept"
      />

      <label :for="id" class="block cursor-pointer">
        <span
          class="py-2 px-5 my-3 inline-block rounded-full bg-blue-dark text-xs text-white"
          ><b>{{ buttonLabel }}</b></span
        >
        <div>
          <p class="text-sm text-gray-500">
            <b class="text-gray-400">archivos soportados</b><br />
            {{ extensions.map((ext) => `${ext}`.replace(".", "")).join(", ") }}
          </p>
        </div>
      </label>

      <ul class="mt-4" v-if="fileList" v-cloak>
        <li class="text-sm p-1 truncate" v-for="(file, i) in fileList" :key="i">
          {{ file.name }}
          <span> <br />({{ (file.size / (1024 * 1024)).toFixed(2) }} MB)</span>
          <button
            class="ml-2 text-red-500"
            type="button"
            @click="remove(file)"
            title="Remove file"
          >
            Eliminar
          </button>
        </li>
      </ul>
    </div>
    <span v-if="maxMB" class="text-red-500 text-center text-xs mt-4"
      >{{ message }}</span
    >
  </div>
</template>
<script>
import checkFileSize from "@/utils/checkFileSize";

export default {
  props: {
    label: String,
    buttonLabel: String,
    required: Boolean,
    disabled: Boolean,
    id: [String, Number],
    fileList: Array,
    extensions: {
      type: Array,
      default: () => [".pdf",".jpg",".jpeg",".png",".mp4",".doc", ".docx", ".xls", ".xlsx", ".bmp", ".mp3",".msg"]
    },
    accept: {
      type: String,
      default: ".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg,application/vnd.ms-outlook, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
    },
    maxMB: [String, Number],
    maxMBPerFile: [String, Number],
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    isAllowedExtension(filename, size = 0){
      // let extensions= [".pdf",".jpg",".jpeg",".png",".mp4",".doc", ".docx", ".xls", ".xlsx", ".bmp", ".mp3",".msg"]
      if (this.maxMBPerFile) {
        const sz = size / (1024*1024)
        return this.extensions.some(extension=>filename.toLowerCase().endsWith(extension)) && sz <= parseInt(this.maxMBPerFile)
      }
      return this.extensions.some(extension=>filename.toLowerCase().endsWith(extension))
    },
    onChange() {
      let newFileList = [...this.fileList];
      let files = [...this.$refs.file.files];
      files.forEach((item) => {
        if (
          this.isAllowedExtension(item.name, item.size)
        ) {
          newFileList.push(this.isValidFileName(item));
        } else {
          this.$notification.error({
              message: "Notificación",
              description: this.maxMBPerFile ? "El formato del archivo no es válido o el peso del mismo supera el permitido "+this.maxMBPerFile+" MB" : "El formato del archivo no es válido",
            });
        }
      });
      if (this.maxMB) {
        if (this.validateMaxMB(newFileList))
          this.$emit("files-handler", newFileList);
      } else this.$emit("files-handler", newFileList);
      this.$refs.file.value = "";
    },
    remove(file) {
      let newFileList = this.fileList.filter((f) => f != file);
      this.$emit("files-handler", newFileList);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-blue-light")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-blue-light");
      }
    },
    isValidFileName(file) {
      if (file?.name.length <= 100) return file;
      else {
        let newFile = new File(
          [file],
          file.name.substring(0, 93) +
            file.name.substring(file.name.length - 7, file.name.length),
          { type: file.type }
        );
        return newFile;
      }
    },
    validateMaxMB(files) {
      if (checkFileSize([...files], this.maxMB)) {
        this.message =
          "*Con el archivo que intenta cargar, se supera el peso máximo permitido para los anexos.";
        return false;
      } else {
        this.feedback = "";
        return true;
      }
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove("bg-blue-light");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove("bg-blue-light");
    },
  },
};
</script>

<style></style>
