import Axios from "axios";
import store from "../../../../store/index";
import { signature } from "../../../../plugins/signature";
import request from "./request";

let axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api",
});

// TODO: delete to product
let axiosForm = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api",
});

// TODO: delete to product
axiosForm.defaults.headers.common["X-SFC-Origin"] =
  process.env.NODE_ENV == "development" ? "desarrollo" : "application";

axios.defaults.headers.common["X-SFC-Origin"] =
  process.env.NODE_ENV == "development" ? "desarrollo" : "application";

export default {
  install(Vue) {
    const refreshToken = async () => {
      let refresh = store.state.session.refresh;

      const response = await axios.post(
        "/token/refresh/",
        { refresh },
        {
          headers: { "X-SFC-Signature": Vue.prototype.$signature({ refresh }) },
        }
      );

      if (response.data) {
        store.commit("session/setSessionAccess", response.data.access);
        store.commit("session/setSessionRefresh", response.data.refresh);
      } else {
        await store.dispatch("session/logout");
        window.location.href = "/login";
      }
    };

    //TODO: delete this axiosForm
    axiosForm.interceptors.request.use(
      function (config) {
        if (config.method == "get") {
          let url = config.baseURL + config.url;
          let sign = Vue.prototype.$signature(url);
          // config.headers["X-SFC-Signature"] = "SFC_5asc4dDf4asf_6Ares";
          config.headers["X-SFC-Invalidate-Signature"] =
            "SFC_5asc4dDf4asf_6Ares";
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        } else if (
          config.method == "post" ||
          config.method == "patch" ||
          config.method == "put"
        ) {
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
      { synchronous: true }
    );

    axios.interceptors.request.use(
      function (config) {
        if (config.method == "get") {
          let url = config.baseURL + config.url;
          let sign = Vue.prototype.$signature(url);
          config.headers["X-SFC-Signature"] = sign;
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        } else if (
          config.method == "post" ||
          config.method == "patch" ||
          config.method == "put"
        ) {
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
      { synchronous: true }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (error.response.status == 401) {
          let msg = error?.response?.data?.message
            ? error?.response?.data?.message[0]?.message
            : "";
          if (
            msg == "El privilegio 'exp' del token ha expirado" ||
            msg == "Token 'exp' claim has expired" ||
            msg == "Token is invalid or expired" ||
            msg == "El token es inválido o ha expirado"
          ) {
            await refreshToken();
            const config = error.config;
            return axios({
              method: config.method,
              url: config.url,
              data: config.data,
              headers: config.headers,
            });
          } else {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );

    //TODO: delete this axiosForm
    axiosForm.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (error.response.status == 401) {
          let msg = error?.response?.data?.message
            ? error?.response?.data?.message[0]?.message
            : "";
          if (
            msg == "El privilegio 'exp' del token ha expirado" ||
            msg == "Token 'exp' claim has expired" ||
            msg == "Token is invalid or expired" ||
            msg == "El token es inválido o ha expirado"
          ) {
            await refreshToken();
            const config = error.config;
            return axios({
              method: config.method,
              url: config.url,
              data: config.data,
              headers: config.headers,
            });
          } else {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );
    Vue.prototype.$request = request;
  },
  axiosForm,
  axios,
  signature,
};
