<template>
  <div class="w-full mt-5 flex flex-col border-0 p-0">
    <label :for="id" 
      class="mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
      :class="{'text-red-500': invalid}"><b>{{label}}</b><span v-if="required" class="text-red-500">*</span></label>
    <select :name="id" 
      class="w-full rounded-lg border-gray-300 text-xs md:text-sm cursor-pointer"
      :class="{'border-red-500':invalid}"
      :value="value"
      @change="updateValue($event.target)">
        <option id="0" value="" class="text-gray-400" disabled selected>- Seleccionar -</option>
        <option 
          class="text-gray-500"
          v-for="option in selectOptions" 
          :key="option[select_key]" 
          :value="option[select_value]">{{option[select_name]}}</option>
    </select>
    <p v-if="invalid" class="mt-2 text-left text-xs text-red-500">Este campo es obligatorio</p>
  </div>
</template>

<script>
export default {
    props: ['id', 'selectOptions', 'label', 'required', 'select_key', 'select_value', 'select_name'],
    data(){
        return {
          value: null,
          invalid: false
        }
    },
    methods: {
      setInvalid(invalid){
        if(this.required){
          this.invalid = invalid;
        }
      },
      updateValue(target){
        this.value = target.value
        this.$emit('input', target.selectedIndex)
      }
    },
    watch: {
      value: function(){
        if(this.value != ''){
          this.invalid = false;
        }
      }
    }
}
</script>

<style>

</style>