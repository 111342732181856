<template>
  <div class="mt-2 text-left flex gap-2 items-center box-border w-full ">
    <div
      class="
        mt-2
        h-full
        sm:w-10/12
        w-11/12
        border border-gray-500
        rounded-lg
        text-xs
        md:text-sm
        flex flex-row
        gap-2
        items-center
      "
    >
      <img
        src="@/assets/icons/file-document.svg"
        class="h-5 my-auto mr-2 pl-5"
      />
      <p class="text-xs text-gray-500 truncate">{{ file_name }}</p>
    </div>
    <button
      v-if="can_be_downloaded"
      class="
        h-full
        sm:w-44
        w-24
        rounded-md
        h-10
        sm:mx-auto
        mx-0
        sm:px-5
        mt-auto
        hover:bg-blue-500
        general-color-button
        flex flex-row
        justify-center
        items-center
      "
      @click="onDownload(value)"
    >
      <img src="@/assets/icons/download.svg" class="h-5 my-auto sm:mr-2" />
      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 16px" spin />
        </a-spin>
      </div>
      <span class="text-xs text-white hidden sm:block">{{ legend }}</span>    
    </button>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: ''
    },
    label: {
      type: String,
      default: 'Anexos'
    },
    value: {
      default: null
    },
    file_name: {
      type: String,
      default: '<not name>'
    },
    can_be_downloaded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      feedback: "",
      legend: "Descargar",
    };
  },
  created(){
    console.log('Created Base', this.file_name)
  },
  methods: {
    async onDownload(value) {
      
      // get url if this does not exists
      let url_download = await this.getUrl(value.bucket, value.full_path);

      let a = document.createElement("a");
      a.href = url_download;
      a.target = "_blank";
      a.download = value;
      a.click();
    },
    async getUrl(bucket, path){
      this.loading = true;
      let encodedBucket = encodeURIComponent(bucket);
      let encodedPath = encodeURIComponent(path);

      let query = `?bucket=${encodedBucket}&path=${encodedPath}`;
      
      let { error, data } = await this.$api.getAttachmentUrlByPath(query);
      
      this.loading = false;

      if (error) this.legend = "Ocurrió un error con la consulta";
      
      if(data){
        this.legend = "Descargar";
        return data.url;
      }
      
      return undefined;
    }
  },
  computed: {},
};
</script>

<style>
</style>