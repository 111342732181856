<template>
  <div class="h-full w-full mt-5 text-left flex flex-col">
    <label
      :for="id"
      class="text-xs md:text-xs text-gray-600 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b>
      <span v-if="legend" class="text-gray-500">{{ legend }}</span>
      <span v-if="required" class="text-red-500">*</span></label
    >
    <p v-if="disabled" class="mt-2 text-lg text-gray-500">
      {{ value }}
    </p>

    <p v-if="invalid" class="mt-2 text-xs text-red-500">
      {{ feedback ? feedback : 'Este campo es obligatorio' }}
    </p>
    <div v-if="!disabled"  class="flex flex-row items-center">
      <a-input-number
        class="mt-2 border border-gray-50 rounded-lg text-xs md:text-sm text-center"
        :class="classObject"
        size="small"
        :min="0"
        :max="999"
        v-model="hh_value"
        :default-value="0"
        :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
        @change="onChange"
      />
      <p class="mt-2 text-lg px-6">:</p>
      <a-input-number
        class="mt-2 border border-gray-50 rounded-lg text-xs md:text-sm"
        :class="classObject"
        :default-value="0"
        size="small"
        :min="0"
        :max="59"
        v-model="mm_value"
        :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'type',
    'label',
    'placeholder',
    'value',
    'disabled',
    'danger',
    'required',
    'autocomplete',
    'legend',
  ],
  data() {
    return {
      invalid: false,
      feedback: '',
      hh_value: 0,
      mm_value: 0
    };
  },
  methods: {
    checkInput() {
      if (this.value == null && this.required) {
        this.invalid = true;
        this.feedback = 'Este campo es obligatorio';
      } else {
        this.invalid = false;
        this.feedback = '';
      }
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    onChange(myval) {
        this.checkInput();
        if (isNaN(myval)) {
            this.invalid = true;
            this.feedback = 'Número inválido';
        }
        let xx = `0 ${this.hh_value < 10 ? '0' + this.hh_value: this.hh_value}:${this.mm_value < 10 ? '0' + this.mm_value: this.mm_value}:00`
        
        this.$emit('input', xx)
    },
  },
  computed: {
    classObject: function () {
      let classes = ['border-gray-300', 'placeholder-gray-400'];
      if (this.danger || this.invalid) {
        classes = ['border-red-500', 'placeholder-red-500'];
      }
      return classes;
    },
  },
};
</script>

<style></style>
