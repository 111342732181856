<template>
  <div class="h-auto w-full mt-5 text-left flex flex-col">
    <label :for="id" v-if="label" class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"
      :class="{ 'text-red-500': invalid }"><b>{{ label }}</b>
      <span v-if="legend" class="text-gray-500">{{ legend }}</span>
      <span v-if="required" class="text-red-500">*</span></label>
    <span v-if="subtitle" class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80 text-gray-500">{{ subtitle
    }}</span>
    <a-tooltip :title="tooltip">
      <a-input ref="input" :type="newType" :placeholder="identification ? idPlaceholder : placeholder" :value="valueInput"
        data-bs-toggle="tooltip" style="height: 40px;text-align: center;" :title="value" @input="inputData" v-numbers-only="numeric"
        @keyup="checkInput" :autocomplete="autocomplete" :disabled="disabled"
        :maxLength="maxlength ? parseInt(maxlength) : calcMaxlengthInput" @blur="util" />
    </a-tooltip>
    <p v-if="invalid" class="mt-2 text-xs text-red-500">
      {{ feedback ? feedback : "Este campo es obligatorio" }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    type: { default: "text" },
    label: String,
    placeholder: String,
    value: String,
    disabled: String | Boolean,
    danger: String | Boolean,
    required: String | Boolean,
    autocomplete: String,
    legend: String,
    subtitle: String,
    maxlength: String | Number,
    identification: String, // cedula, nit, pasaporte, sesn: Sociedad de extrangeria sin nit.
    phoneNumber: Boolean,
    numeric: { type: Boolean, default: false },
    tooltip: String,
  },
  data() {
    return {
      invalid: false,
      feedback: "",
      pattern: "",
      cleaning: false,
      blurValue: "",
    };
  },
  directives: {
    "numbers-only": {
      bind(el, args) {
        if(args.value){
          function checkValue(event) {
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
            if (event.charCode >= 48 && event.charCode <= 57) {
              return true;
            }
            event.preventDefault();
          }
          el.addEventListener("keypress", checkValue);
        }
      }
    },
  },
  methods: {
    validateNumber(data, type) {
      if (type === "number") {

        const ExpRegSoloNumeros = "^[0-9]+$";

        let isNumber = data.trim().match(ExpRegSoloNumeros) != null;

        if (!isNumber) {
          this.invalid = true;
          this.feedback = "Este campo es solo numerico";
          return false;
        }

        return true;

      }
      return true;
    },
    checkInput() {
      if (this.value == "" && this.required) {
        this.invalid = true;
        this.feedback = "Este campo es obligatorio";
      } else {
        this.invalid = false;
        this.feedback = "";
      }
    },
    setInvalid(invalid, type, data) {

      if (type != null && data != null) {

        return this.validateNumber(data, type)

      } else if (invalid != null && this.required) {
        this.invalid = invalid;
        return false;
      }

      return true;
    },
    inputData(event) {
      if (this.validation) {
        event.target.value = event.target.value.replace(this.validation, "");
      }
      this.blurValue = event.target.value
      this.$emit("input", event.target.value);
    },
    clearInput() {
      this.$emit("input", "");
      this.cleaning = false;
    },
    util(event) {
      if (this.identification) {
        if (this.value.length >= 4) {
          if (this.identification == 3) {
            let nit = this.value;
            if (nit != "") {
              if (!this.nitIsValid(nit)) {
                this.invalid = true;
                this.feedback =
                  "El NIT no tiene el formato requerido, por favor verifique.";
              }
            }
          }
        } else {
          this.invalid = true;
          this.feedback = "Identificación inválida.";
        }
      }
      this.$emit("blur", event, this.blurValue)
    },
    nitIsValid(nit) {
      if (!nit) {
        return true;
      }
      let nitRegExp = new RegExp("^[0-9]+(-?[0-9kK])?$");

      if (nitRegExp.test(nit)) {
        if (nit[nit.length - 2] == "-") {
          return true;
        }
      }
    },
  },
  computed: {
    classObject: function () {
      let classes = ["border-gray-300", "placeholder-gray-400"];
      if (this.danger || this.invalid) {
        classes = ["border-red-500", "placeholder-red-500"];
      }
      return classes;
    },
    valueInput: function () {
      return this.value;
    },
    newType: function () {
      return this.identification ? "text" : this.type;
    },

    validation: function () {
      if (this.identification) {
        switch (this.identification) {
          case "3": // NIT
            return /[^\d-]/g;
          case "5": // Pasaporte
            return /[^a-zA-Z0-9-]/g;
          case "7": // Sociedad extranjera sin NIT en Colombia
            return;
          case "8": // Fideicomiso
            return /[^\d-]/g;
          default:
            return this.value[0] != 0 ? /[^0-9]/g : /[^1-9]/g;
        }
      }
      if (this.phoneNumber || this.numeric) {
        return /[^0-9]/g;
      }
    },
    calcMaxlengthInput: function () {
      let max = 100;
      if (this.identification) {
        if (this.identification == 3) {
          max = 14;
        } else if (this.identification == 1) {
          max = 15;
        }
      }
      return max;
    },
    idPlaceholder: function () {
      let placeholder = "Ej: 1223421342";
      if (this.identification == 3) placeholder = "Ej: 921234345343-6";
      else if (this.identification == 5) placeholder = "Ej: 34jk234242";
      return placeholder;
    },
  },
  watch: {
    identification(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.cleaning = true;
        this.clearInput();
      }
    },
  },
};
</script>

<style></style>
