<template>
  <div class="h-full w-full mt-5 text-left flex flex-col">
    <label
      class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80 font-bold"
      >{{ label }}</label
    >
    <a-range-picker
      :value="[start_date, end_date]"
      @change="onChange"
      :placeholder="['Fecha de inicio', 'Fecha de fin']"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: { label: { type: String, default: "Periodo" } },
  data() {
    return {
      start_date: null,
      end_date: null,
    };
  },
  created() {
    this.start_date = moment().set(new Date()).subtract(7, "d");
    this.end_date = moment().set(new Date());
    const dateStringArr = [this.start_date.format("YYYY-MM-DD"), this.end_date.format("YYYY-MM-DD")];
    this.$emit("notify-dates", dateStringArr);
  },
  mounted() {},
  methods: {
    onChange(date, dateString) {
      this.start_date = date[0];
      this.end_date = date[1];
      this.$emit("notify-dates", dateString);
    },
  },
};
</script>

<style scoped></style>
